import * as alasql from 'alasql';
import * as md5 from 'md5';


class Databases{
    constructor(){
        this.database = '';
        this.init();
    }

    aquery(command, params){
        return alasql.promise(command, params).catch( (e) => { console.log("ERROR BASE:",e)});
    }

    async init(){
        //var res = undefined;
        await this.aquery('CREATE INDEXEDDB DATABASE IF NOT EXISTS app_web');
        this.create_initial_db();
    }

    async load(){
        await this.aquery('ATTACH INDEXEDDB DATABASE app_web');
        await this.aquery('USE app_web');
    }

    async create_initial_db(){
        await this.load();
        await this.aquery('CREATE TABLE IF NOT EXISTS usuarios(id string,\
                                                nombre string,\
                                                email string,\
                                                password string,\
                                                rol string,\
                                                created_at DATE,\
                                                tkn string,\
                                                act INT)');
    }

    async query(command, filter){
        await this.load();
        return await this.aquery(command, filter);
    }
    
    encrypt(cadena){
        return md5(cadena);
    }

}

export default Databases;