import { faUsers } from '@fortawesome/free-solid-svg-icons';

class OpcionesSeguridad{

    constructor(){
        this.grupos = [
            { role: 'admin', title:'Administrador' },
            { role: 'cliente', title: 'Cliente' }
        ];

        this.options = [
          {
            icon: faUsers,
            title: 'Usuarios',
            url: '/usuarios',
            enabled: 'admin',
            add: ['admin'],
            editar: ['admin'],
            borrar: ['admin'],
            ver: ['admin','cliente']
          }    
        ];
    }

    getOptions(){
        return this.options;
    }

    getGroups(){
        return this.grupos;
    }

}

export default OpcionesSeguridad;