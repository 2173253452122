var config = {
    environment: 'pre',
    server: {
      url: 'https://alpogo.com'
    },
    firebase: {
      apiKey: "AIzaSyCe4ki-DfCDzyJYM8wS1sWtO8nV6q7D-bY",
      authDomain: "alpogoenvivo.firebaseapp.com",
      databaseURL: "https://alpogoenvivo.firebaseio.com",
      projectId: "alpogoenvivo",
      storageBucket: "alpogoenvivo.appspot.com",
      messagingSenderId: "377276431715",
      appId: "1:377276431715:web:3ba4c73bdad9ffdb8f2756"
    },
    jwt_secret: "vE4V2eAp1h5PEDFALtAoT50lDPupQzoLxuw4im3TFsg4v79Y9QQmHFYAoG1ko8hiFOdG1zr1tAwyFETyGFMG3Qhn7SF7ouLAMX6T"
  }
  
  module.exports = config;
