import React, { Component } from 'react';
import RestService from '../vendors/restservices';
import Storage from '../vendors/storage';
import Alert from 'react-bootstrap/Alert';
import AuthServices from '../vendors/auth.service';
import config from '../config';
import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'

//import 'react-floating-whatsapp/dist/index.css'
import Zendesk from "react-zendesk";

const ZENDESK_KEY = "832416ee-925c-4295-9490-d30fa9c9881f";
const setting = {
  color: {
    theme: "#6204AA"
  },
  webWidget: {
    contactOptions: {
      enabled: true,
      contactButton: { "*": "Get in touch" },
      chatLabelOnline: { "*": "Live Chat" },
      chatLabelOffline: { "*": "Live Chat is unavailable" }
    },
    helpCenter: {
      chatButton: {
        "*": "Chat with us now"
      }
    },
    chat: {
      profileCard: {
        avatar: true,
        rating: false,
        title: true
      }
    }
  }
};

const handleLoadEvent = () => {
  window.zE("webWidget:on", "close", function() {
    console.log("Widget closed!");
  });
  console.log("setting suggestion...");
  window.zE("webWidget", "helpCenter:setSuggestions", {
    search: "student success app"
  });
};

function safeJsonParse(s, def) {
    if (s == null || !s) return def;
    if (typeof (s) != 'string') return s;
    try {
        return JSON.parse(s);
    } catch (err) {
        return def;
    }
}


export default class Login extends Component{

    constructor(props){
        super(props);
        this.restservice = new RestService();
        this.storage = new Storage();
        this.auth = new AuthServices();
        this.state = {
            codeevent: '',
            message: this.props.location.state ? this.props.location.state.message : '',
            showmessage: false,
            session: false
        }
        this.messageElement = React.createRef();
        this.url_ticket = config.server.url+'/cuenta';
        this.codeEvent = React.createRef();
    }

    componentDidMount(){
      this.codeEvent.current.focus()
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleResponse = (response) => {
      this.loginInput.focus()
        return response.text().then(text => {
            const data = text && safeJsonParse(text, {});
            if (!response.ok) {
                if (response.status === 401) {
                    return Promise.reject({ message: 'Unauthorized' })
                }
                const error = data || response.statusText;
                return Promise.reject(error);
            }
            this.setState({
                message: ''
            });
            return data;
        });
    }

    closeSession = (e) => {
      e.preventDefault();
      this.auth.logoutAllSessions(this.state.codeevent)
        .then( (response) =>{
            this.setState( { session: false  });
            this.setState( { message: 'Listo! Intentá acceder ahora.'});
        }).catch( (err) => {
            this.props.history.push('/');
        });
    }


    handleSubmit = (e) => {
      

        e.preventDefault();
        
        if(!this.state.codeevent || this.state.codeevent === ''){
          this.setState({
            message: e.error || 'Ingresá el código de tu e-ticket',
          });
          this.codeEvent.current.focus();
          return true;
        }

        this.auth.login({ codeevent: this.state.codeevent }).then( data => {     
            console.log('DATA', data);       
            if(data && data.conectado === true){
              this.setState({ session: true });
              return;
            }
            this.props.history.push("/");
        }).catch( e =>{
          console.log(e);
          this.setState({
            message: e.error || e.status === 404 ? 'El código ingresado no es válido' : e.error,
            showmessage: true
          });

          this.setState({
            showmessage: false
          });
        });
    }

    render(){
        return (
          <div className="login">
            <div style={{padding:'10% 5% 10% 5%'}}>
              <center>
                <img style={{marginTop: 50, width:250, height:'auto', marginBottom:35}} src="https://alpogo-uploads.s3.us-east-2.amazonaws.com/landings/assets/alpogo-envivo-logo.svg" alt="logo"/>
              </center>

              <form onSubmit={this.handleSubmit}>
                <div className="col-9 offset-2 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-4 offset-lg-4">
                  <div className="form-group">
                    <input className="form-control form-control-sm form-input-sm" placeholder="Por ejemplo: UHNGU" type="text" name="codeevent" value={this.state.codeevent} onChange={this.handleChange} ref={this.codeEvent}/>
                    <p class="subtext">Ingresá el código alfanumérico de tu ticket</p>
                  </div>
                  <center style={ { marginTop:50 } }>
                    {
                      this.state.message ? <Alert className='alert-session'>{this.state.message }</Alert> : <></>
                    }
                    {
                      this.state.session ? <Alert className='alert-session'>El e-ticket está en uso en otro dispositivo. <a href ='#' onClick= { this.closeSession }>Hacé clic aquí</a> e intentá de nuevo.</Alert>: <></>
                    }
                    <button className="btn-access">Acceder</button>
                    <div className="text-tickets" style={ { marginTop:20 } }>
                      <span style={ { color:"white" } }>Encotrá tus e-tickets en </span>
                      <a  href= { this.url_ticket }  rel="noopener  noreferrer" target="_blank">tu cuenta</a>
                    </div>
                  </center>
                </div>
                <Zendesk
                  zendeskKey={ZENDESK_KEY}
                  {...setting}
                  onLoaded={handleLoadEvent}
                />
                {/* <FloatingWhatsApp showPopup = {false} phone='+5493517890971' headerTitle='¿Dudas o consultas?'  popupMessage='¡Chateanos por whatsapp!' headerColor='#FF4131' message='Hola, necesito ayuda.' /> */}
              </form> 
              <div className="wrapper-faqs">
                 <div className="faqs">
                     <div class="faq">
                       <h3>Buscá tu código</h3>
                       <p>Tu código está en tu e-ticket. Encontralo en la <a target='_blank' href='https://alpogo.com/cuenta'>cuenta</a> con la que lo compraste.</p>
                        <img className="img-responsive" src='https://alpogo-uploads.s3.us-east-2.amazonaws.com/landings/assets/envivo-faqs/eticket-v1-app-envivo.jpg'></img>
                     </div>
                      <div class="faq">
                        <h3>Dispositivos</h3>
                       <p>Podés ingresar desde cualquier dispositivo pero, ¡ojo!, sólo de a uno por vez :)</p>
                        <img className="img-responsive" src='https://alpogo-uploads.s3.us-east-2.amazonaws.com/landings/assets/envivo-faqs/devices-v1-app-envivo.jpg'></img>
                      </div>
                      <div class="faq">
                        <h3>Ayuda</h3>
                       <p>Si tenés más dudas buscá en nuestras <a target='_blank' href='https://alpogo.com/pagina/ayuda'>preguntas frecuentes</a></p>
                        <img className="img-responsive" src='https://alpogo-uploads.s3.us-east-2.amazonaws.com/landings/assets/envivo-faqs/faqs-v1-app-envivo.jpg'></img>
                      </div>
                 </div>                      
              </div> 
            </div>
          </div>
        );
    }
}
