import React from 'react';

const Header = (props) => {
    return (
      <div className="header" style={{backgroundColor: 'black'}}>
        <div className="header-wrap">
              <div>
                <img className="logo-alpogo-live" src='https://alpogo-uploads.s3.us-east-2.amazonaws.com/landings/assets/alpogo-en-vivo.png' alt="alpogo-live"/>
              </div>
            <div className='logut-wrapper'>
                <a href="/logout" className='logout-link' title='Para ingresar desde otro dispositivo, primero abandoná esta sala' >Abandonar sala</a>
            </div>
        </div>
      </div>
    );
}

export default Header;
