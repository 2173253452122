import React, {useEffect, useState} from 'react';
import Storage from '../vendors/storage';
import ReactPlayer from 'react-player';
import AuthServices from '../vendors/auth.service';
import Message from '../components/Message';
import Firebase from '../vendors/firebase';
import { useHistory } from "react-router-dom";
import RestService from '../vendors/restservices';

const StreamPlayer = (props) => {
    var storage = new Storage();
    let restservice = new RestService();
    const [urlVideo, setUrl] = useState('');
    const [message, setMessage] = useState('');
    const [showmessage, setShowmessage] = useState(false);
    let history = useHistory();
    


    const _handleContextMenu = (e) => {
        e.preventDefault();
    }

    const read_from_url  = (data) =>{
        data.forEach( (info) => {
            if(info.key === 'message'){ 
              //setMessage(info.val());
              //setShowmessage(true);
            }

            if(info.key === 'url'){
              if( urlVideo === info.val()){
                  return;
              }
              setUrl(info.val());
            }
        });
    }

    useEffect(() => {
        const auth = new AuthServices();
        var session = storage.load('session');  
        //disabled rigth click  
        document.addEventListener('contextmenu', _handleContextMenu);
        if (window.performance) {
            if (performance.navigation.type == 1) {
              var token   = storage.load('x_access_token', '');
              restservice.get('/api/eventos/streaming/detalles', { token: token}).then( (data) => {
                setUrl(data.url_streaming);
              }).catch( (err) => {
                console.log('Error en proceso de login: ', err);
                setUrl('');
                history.push('/logout');                
              });
            }else{
              setUrl(session.evento_url);
            }
        }else{
            setUrl(session.evento_url);    
        }        

        //LEEMOS LA LLAVE DE FIREBASE
       Firebase.read(session.firebase_key, read_from_url).catch( (err) => {
            if(err){
              setMessage(err);
              setShowmessage(true);
            }
        });
    }, []);

      // Testeo las variables de player

    return (
      <div className="header" style={{backgroundColor: 'black'}}>
          <Message isOpen = { showmessage } title = 'ALPOGO - EN VIVO' messageDetails = { message } onClose={ () => setShowmessage(false) }/>
          {
            message ?   <div className="text-tickets" style={ { marginTop:20 } }>
                        <span style={ { color:"white" } }>{ message }</span>
                      </div>
                      : <></>
          }
          <ReactPlayer url = { urlVideo } playing 
                        controls = { true } 
                        allowFullScreen = { true } width='100%' height='35vw' 
                        config={{ 
                            youtube: { 
                              attributes: {
                                onContextMenu: e => e.preventDefault()
                              } 
                            } 
                          }}

            />
      </div>
    );
}

export default StreamPlayer;