import {Component} from 'react';
import AuthServices from '../vendors/auth.service';

export default class Logout extends Component {

    constructor(props ){
        super(props);
        this.auth = new AuthServices();
    }
    
    componentWillMount(){
        this.auth.logout()
        .then( (response) =>{
            this.props.history.push('/');
        }).catch( (err) => {
            this.props.history.push('/');
        });
    }
    
    render(){
        return null;
    }


}