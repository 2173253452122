import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const Message = (props) => {
    const [show, setShow] = useState(false);
    const {  isOpen, onClose, onSubmit } = props;

    return (
        <>
        <Modal show={isOpen} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.messageDetails}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default Message;
