import Cookies from 'js-cookies';
 
class Storage{

    load(key, def) {
        var v = Cookies.getItem(key);
        if(v == null || v === undefined) return def;
        try {
          return JSON.parse(v);
        } catch (e) {
          return def;
        }
    }

    save(key, value) {
        Cookies.setItem(key, JSON.stringify(value));
    }

    delete(key) {
        Cookies.removeItem(key);
    }

}


export default Storage;
