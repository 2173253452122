import React from 'react';
import './App.css';
import Routes from './Routes';
//FIREBASE CLOUD MESSAGE
//import Firebase from './vendors/firebase';

//import Message from './components/Message';

function App() {
  return (
    <div className="App" >
        {/* <Header title="Soy un header"></Header> */}
        <Routes></Routes>
        
    </div>

  );
}

export default App;
