import Storage from './vendors/storage';
import Options from './vendors/options';
import config from './config';
const jwt = require('jsonwebtoken');
const moment = require('moment');

class Auth {
    constructor() {
        var opt = new Options();
        this.storage = new Storage();
        this.options = opt.getOptions();
    }

    isAuth(route){
        var decoded = undefined;
        const token = this.storage.load('x_access_token');
        const session  = this.storage.load('session');
        //Si no tiene token no permitir el ingreso
        if (token == null) return false;
        console.log('Toke: ', token);
        try{
            decoded = jwt.verify(token, config.jwt_secret);
        }catch(e){
            this.storage.delete('session');
            this.storage.delete('x_access_token');
            return false;
        }
        var current_time = moment().unix();
        console.log('Validate time for jwt from: ', current_time);
        console.log('Time to: ', decoded.exp);
        if (current_time > decoded.exp) return false;
        if(route !== '/' && route !== ''){
            console.log('Validando options', route);
            console.log('Validando options', session);    
            console.log('Resultado de find:', this.options.find( option => route === option.url && session.rol === option.enabled ));
            return this.options.find( option => route === option.url && session.rol === option.enabled );
        }
        return true;
    }
}   

export default new Auth();