import firebase from 'firebase';
import Config from '../config.js';

class Firebase{
    constructor(){
        this.firebase = undefined;
        if(!Config.firebase) return;
        this.firebase = firebase.initializeApp(Config.firebase);
        this.database = firebase.database();
    }

    get(){
        return this.database;
    }

    read(key, exec ){
        return new Promise( (resolve, reject) => {
            try{
                this.database.ref(key).on("value", exec);
                resolve();
            }catch(error){
                reject(error);
            }
        });
    }
}

export default Firebase = new Firebase();
