/*
Function: class to execute call to remote API services
*/

import Storage from '../vendors/storage';
import Config from '../config.js';
import Database from '../vendors/databases';

function safeJsonParse(s, def) {
    if (s == null || !s) return def;
    if (typeof (s) != 'string') return s;
    try {
        return JSON.parse(s);
    } catch (err) {
        return def;
    }
}


class RestServices{

    constructor(){
        this.url = '';
        this.url_api = Config.server.url;
        this.storage = new Storage();
        this.databases = new Database();
        //console.log('SELECT: ',this.databases.select('SELECT * FROM products WHERE id = ?',[parseInt(11)]));
    }

    get(url, data){
        return new Promise( (resolve, reject) => {
            if(!url || url === '' ) throw new Error('Url is required');
            var requestOptions = this.options(url,data,'GET');
            return this.call(this.url_api+this._url.urlWithParams, requestOptions).then(data => { resolve(data) }).catch( e => { console.log(e); reject(e); });
        });
    }

    post(url, data){
        return new Promise( (resolve, reject) => {
            if(!url || url === '' ) return reject('Url is required');
            console.log('URL GENERAL: ', url);
            var requestOptions = this.options(url, data, 'POST');
            console.log('CONNECTO: ', this.url_api+url);
            return this.call(this.url_api+url, requestOptions).then( data => { resolve(data)}).catch( e => { reject(e)});
        });
    }

    put(url, data){
        return new Promise( (resolve, reject) => {
            if(!url || url === '' ) return reject('Url is required');
            var requestOptions = this.options(url, data,'PUT');
            return this.call(this.url_api+this._url.url, requestOptions).then(data => { resolve(data)}).catch( e => { reject(e)});
        });
    }

    delete(url, data){
        return new Promise( (resolve, reject) => {
            if(!url || url === '' ) return reject('Url is required');
            var requestOptions = this.options(url,data,'DELETE');
            return this.call( this.url_api+this._url.url, requestOptions).then(data => { resolve(data)}).catch( e => { reject(e)});
        });
    }

    options(url, data, type){
        this._url = this.renderUrl(url, data);
        console.log('URL: ', this._url.urlWithParams);

        const requestOptions = {
            method: type,
            headers:  new Headers(this.getHttpConfig()),
            body: type === 'POST' || type === 'PUT' ? JSON.stringify(this._url.body) : undefined,
            mode: 'cors'
        };

        return requestOptions;
    }

    isOnline(url, data){
        return new Promise( (resolve, reject) => {
            if(!url || url === '' ) throw new Error('Url is required');
            var requestOptions = this.options(url, data, 'GET');
            return this.call(url, requestOptions).then(data => { resolve(data)}).catch( e => { reject(e)});
        });

    }

    /*
    * FUNCTION: execute request to the specified url and requestOptions
    */
    call(url, requestOptions){
        return new Promise( (resolve, reject) => {
            fetch(url, requestOptions)
            .then(response => {
                if (response.ok) return resolve(response.json());
                return response.text().then(function (text) {
                    if (response.status === 401) return reject({ error: 'Unauthorized' })
                    var error = '';
                    if( response.status !== 200 ){
                        const data = text && safeJsonParse(text, {});
                        const problems = (data.problems && data.problems.length) > 0 ? data.problems.toString() : undefined;
                        error = { status: response.status,
                                  error:  problems || data.message || response.statusText };
                    } else {
                        error = 'Server is down!'
                    }
                    return reject(error);
                });
            }).then(data => {
                return reject(data)
            }).catch(e => {
                console.log(e);
              return reject({status: 500, error: 'Server not found!'})
            });

        });
    }


    getHttpConfig() {
        //let token = this.storage.load('x_access_token');
        let token = undefined;
        return token ? { 'Content-Type': 'application/json' , 'x-access-token': this.storage.load('x_access_token') } : { 'Content-Type': 'application/json' };
    }


    renderUrl(template, data) {
        if(!data) return { url: template, urlWithParams: template, body: undefined };
        // var regex = /([^:])+([^\/])+/ig;
        var regex = /([^:])+([^/])+/ig;
        var parts = [];
        var usedVars = [];
        var queryParams = [];
        var body = {};
        var m;
        while ((m = regex.exec(template)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
              regex.lastIndex++;
          }
          var p = m[0].split(':');
          parts.push(p[0]);
          if(p.length > 1) {
            var v = p[1];
            parts.push(data[v]);
            usedVars.push(v);
          }
        }
        for(var k in data) {
          if(usedVars.indexOf(k) < 0 && data[k] !== undefined) {
            queryParams.push(k + '=' + data[k]);
            body[k] = data[k];
          }
        }

        var url = parts.join('');
        var urlWithParams = url;
        if(queryParams.length > 0) {
          urlWithParams += '?' + queryParams.join('&');
        }

        return { url: url, urlWithParams: urlWithParams, body: body };
      }

}


export default RestServices;
