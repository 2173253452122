import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from './Auth';

const ProtectedRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={  
                props => {
                    if (Auth.isAuth(props.location.pathname)) {
                        return <Component {...props}></Component>
                    }else{
                        return <Redirect to={{
                            pathname: '/login',
                            state: {
                                from: props.location,
                                message: ''
                            }
                        }} />
                    }
                }
            }
        >
        </Route>
    );
}

export default ProtectedRoute;
