import Storage from '../vendors/storage';
import RestServices from '../vendors/restservices';
import Usuario from '../data/usuarios';
import config from '../config';

const jwt = require('jsonwebtoken');
// import jwtDecode from 'jwt-decode';

class AuthServices{
    constructor(){
        this.storage   = new Storage();
        this.restservice = new RestServices();
        this.session   = undefined;
        this.usuario   = new Usuario();
        this.jwt_token = 'A54AAS56EWA549AS78WQHUSUR545FADS';
        
    }

    setSession(){
        this.session = this.storage.load('session',{});
    }

    saveSession(data){
        this.session = data;
        this.storage.save('session', data);
        this.storage.save('x_access_token', data.access_token);
    }

    deleteSession(){
        this.storage.delete('session');
        this.storage.delete('x_access_token');
    }

    getSession(){
        return new Promise( (resolve, reject) => {
            var self = this;
            this.restservice.get('api/v1/session').then( data => {
                self.saveSession(data);
                resolve(data);
            }).catch( e => { reject(e) })
        });
    }

    login(opts){
        return new Promise( (resolve, reject) => {
            var self = this;
            //FAKE BACKEND
            //Codigo maligno bug in app FAKE - BACKEND
            this.restservice.get('/api/eventos/streaming', { codigo: opts.codeevent, token: this.storage.load('x_access_token', '') })
                .then(data => {
                    if(!data.access_token) return resolve(data);
                    let jwt_str = jwt.decode(data.access_token, config.jwt_secret);
                    jwt_str.access_token = data.access_token;
                    jwt_str.codigo = opts.codeevent;
                    self.saveSession(jwt_str);
                    return resolve(data);
                })
                .catch(e => {
                    reject(e);
                });
        });
    }

    logout(){
        var session = this.storage.load('session', {});
        var token   = this.storage.load('x_access_token', '');
        this.storage.delete('session');
        this.storage.delete('x_access_token');

        return new Promise( (resolve, reject) => {
            this.restservice.post('/api/eventos/streaming', 
                                                        { codigo: session.codigo || '', 
                                                          token: token || '' 
                                                        }) 
                .then( data => { 
                    resolve();
                })
                .catch( e => {
                    console.log('Retornando por el error', e); 
                    reject(e);
                });
        });
    }

    logoutAllSessions(codigo = undefined){
        return new Promise( (resolve, reject) => {
            this.restservice.post('/api/eventos/streaming', 
                                                        { codigo: codigo || '', 
                                                          token: '' 
                                                        }) 
                .then( data => { 
                    resolve();
                })
                .catch( e => {
                    console.log('Error al cerrar la sesion', e); 
                    reject(e);
                });
        });

    }

}


export default AuthServices;
