import Databases from '../vendors/databases';
import * as md5 from 'md5';
import uuid from 'react-uuid';
import Storage from '../vendors/storage';
//import Auth from '../vendors/auth.service';

class Usuario{

    constructor(){
        this.database = new Databases();
        this.storage = new Storage();
        this.session = this.storage.load('session');
        this.rol     = 'admin';
        this.permission = true;

        if( this.session && this.session.rol !== this.rol) this.permission = false;
        console.log('USUARIO SESION: ', this.session);
        //this.auth = new Auth();
    }

    query(sql, filter){
        return new Promise( (resolve, reject) => {
            try{
                var data = this.database.query(sql, filter);
                resolve(data);
            }catch(e){
                reject(e);
            }
        });
    }

    enabled(token){
        return new Promise(resolve => {
            this.database.query('UPDATE usuarios SET act = 1 WHERE tkn = ?',[token]).then( (data) => {
                resolve();
            }).catch( (e) => {
                resolve();
            });
        });
    }

    getByEmail(email = ''){
        return new Promise(resolve => {
            return this.database.query('SELECT * FROM usuarios WHERE email = ?',[email]).then( (data) => {
                resolve(data);
            });
        });
    }


    get(email = '', nombre = ''){
        return new Promise( resolve => {
            return this.database.query('SELECT * FROM usuarios WHERE (email = ? or ? = "")',[email,email]).then( (data) =>{
                resolve(data);
            });
        })
    }

    getByRole(rol = ''){        
        return new Promise(resolve => {
            return this.database.query('SELECT * FROM usuarios WHERE rol = ?',[rol]).then( (data) => {
                resolve(data);
            });
        });            
    }

    login( email, password){
        return new Promise( ( resolve, reject) =>{
            return this.database.query('SELECT * FROM usuarios WHERE email = ? and password = ?',[email, md5(password)]).then( (data) => {
                resolve(data);
            }).catch( (e) =>{
                reject(e);
            })
        });        
    }

    add(str_usuario){
        return new Promise( (resolve, reject) => {
            try{
                this.database.query('INSERT INTO usuarios(id, name, email, password, rol, created_at, tkn, act) VALUES(?,?,?,?,?,?,?,?)',
                    [uuid(), str_usuario.apellido ? str_usuario.apellido+', '+str_usuario.nombre : str_usuario.nombreApellido , str_usuario.email,md5(str_usuario.password), str_usuario.rol, new Date(), md5(str_usuario.email), 0], )
                resolve();
            }catch(e){
                reject(e);
            }
        });
    }

    delete(usuario){
        return new Promise( (resolve, reject) => {
            console.log('ROL DEL USUARIO: ', this.permission);
            if(!this.permission) return reject('El usuario no tiene permisos');
            this.database.query('DELETE FROM usuarios WHERE id = ?',[usuario.id]).then( () => {
                resolve();
            }).catch( (e) => {
                reject(e);
            })
        })
    }

    update(str_usuario){
        return new Promise( (resolve, reject) => {
                if(!this.permission) return reject('El usuario no tiene permisos');
                
                this.database.query('UPDATE usuarios SET name = ?, email = ?, password = ?, rol = ? WHERE id = ?',
                    [str_usuario.name, str_usuario.email, str_usuario.password, str_usuario.rol, str_usuario.id]).then( (e)=>{
                        console.log('DATOS ACTUALIZADOS CORRECTAMENTE');
                        resolve();
                    }).catch( (e) =>{
                        console.log('ERROR: ', e)
                        reject(e);
                    });
        });
    }
}


export default Usuario;