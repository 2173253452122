import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import StreamPlayer from '../components/StreamPlayer';
import Chat from '../components/Chat';

import Firebase from '../vendors/firebase';
import Storage from '../vendors/storage';
import { useHistory } from "react-router-dom";


const Home = () => {
  var storage = new Storage();
  let history = useHistory();

  
  const read_from_firebase  = (data) =>{
    data.forEach( (info) => {
      if(info.key == 'estado'){
        console.log('ESTADO PARA EJECUTAR::::: ', info.val());
        if(info.val() === 'inactivo'){
          history.push('/logout');
        }
      }
    });
  };


  useEffect(() => {
    var session = storage.load('session');
    console.log('INICIANDO HOME: ', session);

    Firebase.read(session.codigo, read_from_firebase).catch( (err) => {
      if(err){
        console.log('ERROR REMOTE DATABASE: ', err);
      }
  });
  }, []);

    return (
        <div className="wrap">
            <div className="header_content">
              <Header title=""></Header>
              <div className="content">
                <StreamPlayer/>
              </div>
            </div>
        </div>
    );
}

export default Home;
