import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './components/Logout';
import ProtectedRoute from './ProtectedRoute';
//https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/login" component = { Login }></Route>
                <Route exact path="/logout" component = { Logout }></Route>
                <ProtectedRoute exact path = "/" component={ Home }>
                </ProtectedRoute>
                <Route path="*" component={() => "NOT FOUND"}></Route>
            </Switch>

        </Router>
    );
}

export default Routes;
